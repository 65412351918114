/* You can add global styles to this file, and also import other style files */

.ng-invalid:not(form) {
  border-left:5px solid red;
}

.ng-valid:not(form) {
  border-left:5px solid green;
}

.material {
  @import "~ng-snotify/styles/material";
}

.btn {
  border-radius: 3% 3% 3% 3%;
}

.btn-primary {
  box-shadow: 7px 8px 25px -7px rgb(0 0 0 / 50%);
  background-color: #5C2482 !important;
  border-color: transparent !important;
}

.btn-primary:hover:not([disabled]) {
  background-color: #76B72A !important;
}

.badge-bdkj {
  background-color: #1BBBE9 !important;
}

.bdkj-required {
  color: rgb(226, 0, 26)
}
